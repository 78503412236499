import { SignedInUser } from "@behaviour-lab/blab-component-library/auth"
import dynamic from "next/dynamic"

interface StaffToolbarProps {
  user: SignedInUser | null
}

// Dynamically import vercel toolbar so it only renders under the expected conditions
const VercelToolbar = dynamic(
  async () => {
    const { VercelToolbar: VercelToolbarInternal } = await import(
      "@vercel/toolbar/next"
    )
    return VercelToolbarInternal
  },
  {
    ssr: false,
  },
)

/**
 * Staff toolbar - currently a wrapper around the Vercel toolbar.
 * Does not inject the toolbar in development mode.
 * Does not render in production.
 * Only shows the toolbar to internal behaviour lab users.
 */
export default function StaffToolbar({ user }: StaffToolbarProps) {
  // We do not want to inject the toolbar in development mode
  const isLocalDevelopment = process.env.NODE_ENV === "development"
  const isNotBehaviourLabUser = !user?.email?.includes("@behaviourlab.com")
  const isProduction = process.env.NEXT_PUBLIC_TARGET_ENV === "production"

  if (isProduction || isLocalDevelopment || isNotBehaviourLabUser) return null

  return <VercelToolbar />
}
