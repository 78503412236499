import * as React from "react"

import {
  IconButton,
  Form,
  FormToggle,
  FormInputField,
  InputTypeEnum,
  Button,
  ButtonVariantEnum,
  FormSelectField,
  SelectOptionType,
  FormApi,
  getButtonClassName,
  ButtonBorderEnum,
  ButtonSizeEnum,
  TooltipWithInfoIcon,
} from "@behaviour-lab/blab-component-library/components"
import ArrowBackIcon from "@behaviour-lab/blab-component-library/icons/common/ArrowBackIcon"
import ConstructionIcon from "@behaviour-lab/blab-component-library/icons/solid/ConstructionIcon"

import SidePanel from "src/components/layouts/common/SidePanel"
// eslint-disable-next-line import/no-cycle
import {
  useDebugContext,
  DebugPropValue,
  DebugDataType,
} from "src/context/debug-context"

const isInteger = (paramValue: DebugPropValue) =>
  paramValue.type === "integer" ||
  (typeof paramValue.type !== "string" &&
    paramValue.type.anyOf?.includes("integer"))
const isSelect = (paramValue: DebugPropValue) => !!paramValue.options?.length

// Here we compare the form values with the values that come from BE and return only the changed ones to put them into the request
const formatDebugParametersFromValues = (
  values: FormValuesType,
  debugData: DebugDataType,
) => {
  return Object.fromEntries(
    Object.entries(values).reduce(
      (acc: Array<[string, string | boolean | number]>, [key, value]) => {
        if (isInteger(debugData[key])) {
          if (
            typeof value !== "boolean" &&
            typeof value !== "object" &&
            (!!value || Number(value) === 0)
          ) {
            acc.push([key, Number(value)])
          }

          return acc
        }

        if (isSelect(debugData[key])) {
          const typedValue = value as SelectOptionType

          if (debugData[key].value !== typedValue.value) {
            acc.push([key, typedValue.value])
          }

          return acc
        }

        if (typeof value === "boolean" && debugData[key].value !== value) {
          acc.push([key, value])

          return acc
        }

        return acc
      },
      [],
    ),
  )
}

const formatInitialValuesFromDebugData = (
  debugData: DebugDataType,
  propertyName: "value" | "default",
) => {
  return Object.fromEntries(
    Object.entries(debugData).map(([key, value]) => {
      if (isSelect(debugData[key])) {
        return [
          key,
          debugData[key].options.find(o => o.value === value[propertyName]),
        ]
      }

      return [key, value[propertyName]]
    }),
  )
}

type FormValuesType = Record<
  string,
  string | boolean | number | SelectOptionType | null
>

const DebugToolbar = () => {
  const { debugData, setDebugParameters, debugParameters } = useDebugContext()

  if (!debugData) return null

  const handleSubmit = (values: FormValuesType) => {
    const formattedValues = formatDebugParametersFromValues(values, debugData)

    setDebugParameters(formattedValues)
  }

  // These initial values are based on the debugData that comes from API
  // The API returns default and value. Value is defined in serverless.yml per environment.
  const initialValuesBasedOnValue = formatInitialValuesFromDebugData(
    debugData,
    "value",
  )

  const initialValuesBasedOnDefault = formatInitialValuesFromDebugData(
    debugData,
    "default",
  )

  // If there are changed debugParameters, then initialValues are calculated from them;
  // if there are no debugParameters, then we set the initialValues from the debugData (data loaded from API)
  const initialValues = !debugParameters
    ? initialValuesBasedOnValue
    : {
        ...initialValuesBasedOnValue,
        ...Object.fromEntries(
          Object.entries(debugParameters).map(([key, value]) => {
            if (isSelect(debugData[key])) {
              return [key, debugData[key].options.find(o => o.value === value)]
            }

            return [key, value]
          }),
        ),
      }

  return (
    <SidePanel
      TriggerButton={<ConstructionIcon className="size-6" />}
      triggerProps={{
        className: `fixed bottom-10 right-10 z-[70] ${getButtonClassName({ variant: ButtonVariantEnum.Primary, borderType: ButtonBorderEnum.FullCircle, className: "px-0 py-0 justify-center", size: ButtonSizeEnum.L })}`,
      }}
      title="Debug Mode Toolbar"
    >
      {({ onClose }) => {
        return (
          <Form
            onSubmit={values => {
              handleSubmit(values)
              onClose()
            }}
            initialValues={initialValues}
            render={({ pristine, form: apiForm }) => {
              const form = apiForm as FormApi<FormValuesType>

              return (
                <div className="relative space-y-10">
                  {Object.entries<DebugPropValue>(debugData).map(
                    ([key, value]: [string, DebugPropValue]) => {
                      return (
                        <div key={key} className="space-y-2">
                          <div className="flex items-center gap-2">
                            <h3 className="font-medium">{value.title}</h3>
                            <TooltipWithInfoIcon content={key} />
                          </div>
                          {value.description && (
                            <p className="text-sm text-olive-1000">
                              {value.description}
                            </p>
                          )}
                          {value.type === "boolean" && (
                            <FormToggle name={key} />
                          )}
                          {isInteger(value) && (
                            <div className="flex items-center gap-2">
                              <FormInputField
                                name={key}
                                type={InputTypeEnum.NUMBER}
                                placeholder="Type number..."
                                containerClassName="grow"
                              />
                              <IconButton
                                tooltipProps={{
                                  content: "Reset to default",
                                }}
                                variant={ButtonVariantEnum.WhiteNoBorder}
                                icon={
                                  <ArrowBackIcon className="size-6 text-oliveA-900" />
                                }
                                onClick={() => {
                                  form.change(key, null)
                                }}
                                disabled={
                                  !["number", "string"].includes(
                                    typeof form.getFieldState(key)?.value,
                                  )
                                }
                              />
                            </div>
                          )}
                          {isSelect(value) && (
                            <div className="flex items-center gap-2">
                              <FormSelectField
                                name={key}
                                options={value.options}
                                className="grow"
                                contentProps={{
                                  className: "z-[101]",
                                }}
                              />

                              <IconButton
                                tooltipProps={{
                                  content: "Reset to default",
                                }}
                                variant={ButtonVariantEnum.WhiteNoBorder}
                                icon={
                                  <ArrowBackIcon className="w-6 h-6 text-oliveA-900" />
                                }
                                onClick={() => {
                                  form.change(key, null)
                                }}
                                disabled={form.getFieldState(key)?.pristine}
                              />
                            </div>
                          )}
                        </div>
                      )
                    },
                  )}

                  <div className="sticky bottom-0 flex items-center justify-end gap-2 pt-2 pb-6 bg-olive-100">
                    <Button
                      variant={ButtonVariantEnum.White}
                      disabled={!debugParameters}
                      onClick={() => {
                        form.reset()
                        setDebugParameters(
                          formatDebugParametersFromValues(
                            initialValuesBasedOnDefault,
                            debugData,
                          ),
                        )
                        onClose()
                      }}
                    >
                      Reset to default
                    </Button>
                    <Button disabled={pristine} isSubmit>
                      Apply
                    </Button>
                  </div>
                </div>
              )
            }}
          />
        )
      }}
    </SidePanel>
  )
}

export default DebugToolbar
