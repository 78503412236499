class LocalStorageManager {
  public saveAsDefaultFeatureKey: string

  constructor() {
    this.saveAsDefaultFeatureKey = "is_save_as_default_feature_supressed"
  }

  public getIsSaveAsDefaultFeatureNoticeSupressed() {
    return window.localStorage.getItem(this.saveAsDefaultFeatureKey) === "true"
  }

  public setIsSaveAsDefaultFeatureNoticeSupressed() {
    window.localStorage.setItem(this.saveAsDefaultFeatureKey, "true")
  }

  public clear() {
    window.localStorage.clear()
  }
}

export default LocalStorageManager
