import * as React from "react"

import { useSWRConfig } from "swr"

// eslint-disable-next-line import/no-cycle
import { DebugToolbar } from "src/components/DebugToolbar"
import { useGetDebugParametersSWR } from "src/lib/swr/debug"

export type DebugPropValue = {
  value: string | boolean | number
  default: string | boolean | number
  title: string
  description: string
  type: "integer" | "boolean" | { anyOf: ["integer" | "boolean"] }
  options: Array<{ label: string; value: string }>
  expires_on: string
}

export type DebugDataType = Record<string, DebugPropValue>

export type DebugParametersType = Record<
  string,
  string | boolean | number
> | null

interface IProps {
  children: React.ReactNode
}

type DebugContext = {
  debugData: DebugDataType | undefined
  debugParameters: DebugParametersType
  setDebugParameters: React.Dispatch<React.SetStateAction<DebugParametersType>>
}

export const DebugContext = React.createContext<DebugContext | undefined>(
  undefined,
)

export function DebugContextProvider({ children }: IProps) {
  const { data } = useGetDebugParametersSWR(
    process.env.NEXT_PUBLIC_TARGET_ENV === "local" ||
      process.env.NEXT_PUBLIC_TARGET_ENV === "development" ||
      process.env.NEXT_PUBLIC_TARGET_ENV === "staging",
  )

  // It is needed to persist state on changing pages
  const { cache, mutate } = useSWRConfig()
  const cacheKey = "debug-mode-tools"

  const [debugParameters, setDebugParameters] =
    React.useState<DebugParametersType>(cache.get(cacheKey)?.data || {} || null)

  React.useEffect(() => {
    mutate(cacheKey, debugParameters)
  }, [debugParameters])

  return (
    <DebugContext.Provider
      value={{
        debugParameters,
        setDebugParameters,
        debugData: data,
      }}
    >
      {children}
      {!!data && <DebugToolbar />}
    </DebugContext.Provider>
  )
}

export const useDebugContext = () => {
  const context = React.useContext(DebugContext)

  if (!context) {
    throw new Error("useDebugContext must be used within DebugContextProvider")
  }

  return context
}
