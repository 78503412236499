import { useAuth } from "@behaviour-lab/blab-component-library/auth"
import useSwr from "swr"

import { getFetcher, ApiUrl } from "./fetcher/instances"
import { fetcherOptions } from "./fetcher/options"

export const useGetDebugParametersSWR = (shouldDebugDataBeFetched: boolean) => {
  const { logout } = useAuth()

  return useSwr(
    shouldDebugDataBeFetched ? "/analytics/describe/debug" : null,
    url =>
      getFetcher(ApiUrl.NEXT_PUBLIC_ANALYTICS_API, {
        url,
        tokenExpiredCb: logout,
      }),
    fetcherOptions,
  )
}
