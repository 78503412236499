import { UserManagementService } from "@behaviour-lab/blab-component-library/auth"

export type HttpMethod = "GET" | "POST" | "PUT" | "DELETE"

export const INVALID_TOKEN_ERROR_MESSAGE = "Invalid JWT Token"

export type SWRFetcherProps = {
  url: string
  tokenExpiredCb?: () => void
  options?: { arg?: unknown }
}

export const fetcher =
  (method: HttpMethod, domain: string | undefined) =>
  async ({ url, options }: SWRFetcherProps) => {
    if (!domain) {
      return Promise.reject("API not received")
    }

    const token = await UserManagementService.getIdToken()

    if (!token) {
      return Promise.reject("Token not received")
    }

    const response = await fetch(`${domain}${url}`, {
      method: method,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      ...(["GET", "DELETE"].includes(method)
        ? {}
        : { body: JSON.stringify(options?.arg) }),
    })

    if (!response.ok) {
      const result = await response.json()

      if (
        result.message === INVALID_TOKEN_ERROR_MESSAGE ||
        response.status === 401
      ) {
        return Promise.reject(INVALID_TOKEN_ERROR_MESSAGE)
      }

      return Promise.reject(
        result.message ||
          result.error ||
          result.errorMessage ||
          "Something went wrong",
      )
    }

    const res = (await response.json()) || {}
    const { data, result } = res

    // data is used in CRUD responses
    // result is used in analytics responses
    // just res as a reponse is used in analytics settings responses
    return data || result || res
  }
