import { FilterStateType, SectionNameEnum, PropType } from "src/types"

class SessionStorageManager {
  constructor() {}

  public getSectionFiltersKey(
    orgId: number,
    portfolioId: number,
    sectionId: SectionNameEnum,
  ) {
    return `${orgId}_${portfolioId}_${sectionId}`
  }

  public getSectionFilters(
    orgId: number,
    portfolioId: number,
    sectionId: SectionNameEnum,
  ): PropType<FilterStateType, "appliedFilters"> | null {
    const retrievedResult = window.sessionStorage.getItem(
      this.getSectionFiltersKey(orgId, portfolioId, sectionId),
    )

    return retrievedResult ? JSON.parse(retrievedResult) : null
  }

  public setSectionFilters(
    orgId: number,
    portfolioId: number,
    sectionId: SectionNameEnum,
    appliedFilters: PropType<FilterStateType, "appliedFilters">,
  ) {
    window.sessionStorage.setItem(
      this.getSectionFiltersKey(orgId, portfolioId, sectionId),
      JSON.stringify(appliedFilters),
    )
  }

  public clear() {
    window.sessionStorage.clear()
  }
}

export default SessionStorageManager
