import { handleRefreshTokenAndRetry } from "src/lib/common/handleRefreshTokenAndRetry"

import {
  fetcher,
  SWRFetcherProps,
  INVALID_TOKEN_ERROR_MESSAGE,
} from "./fetcher"

export enum ApiUrl {
  NEXT_PUBLIC_CRUD_API = "NEXT_PUBLIC_CRUD_API",
  NEXT_PUBLIC_ANALYTICS_API = "NEXT_PUBLIC_ANALYTICS_API",
}

const UrlMap = {
  [ApiUrl.NEXT_PUBLIC_CRUD_API]: process.env.NEXT_PUBLIC_CRUD_API,
  [ApiUrl.NEXT_PUBLIC_ANALYTICS_API]: process.env.NEXT_PUBLIC_ANALYTICS_API,
}

async function withRefreshTokenAndRetry<DataType>(
  customFetcher: ({
    url,
    tokenExpiredCb,
    options,
  }: SWRFetcherProps) => Promise<DataType>,
  { url, tokenExpiredCb, options }: SWRFetcherProps,
): Promise<DataType> {
  try {
    const res = await customFetcher({ url, options })

    return res
  } catch (e) {
    if (e === INVALID_TOKEN_ERROR_MESSAGE) {
      return await handleRefreshTokenAndRetry<DataType>({
        url,
        tokenExpiredCb,
        retry: async () => await customFetcher({ url, options }),
      })
    }

    return Promise.reject(e)
  }
}

export const getFetcher = async (apiKey: ApiUrl, args: SWRFetcherProps) =>
  await withRefreshTokenAndRetry(fetcher("GET", UrlMap[apiKey]), args)

export const deleteMutator = async (apiKey: ApiUrl, args: SWRFetcherProps) =>
  await withRefreshTokenAndRetry(fetcher("DELETE", UrlMap[apiKey]), args)

export const postMutator = async (apiKey: ApiUrl, args: SWRFetcherProps) =>
  await withRefreshTokenAndRetry(fetcher("POST", UrlMap[apiKey]), args)

export const putMutator = async (apiKey: ApiUrl, args: SWRFetcherProps) =>
  await withRefreshTokenAndRetry(fetcher("PUT", UrlMap[apiKey]), args)
