import * as React from "react"

import {
  RadixDialog,
  RadixDialogTriggerProps,
  classNames,
} from "@behaviour-lab/blab-component-library/components"

interface ISidePanelProps {
  TriggerButton: React.ReactNode
  title: string
  description?: string
  triggerProps?: RadixDialogTriggerProps
  contentClassName?: string
  children: (props: { onClose: () => void }) => React.ReactNode
}

const SidePanel = ({
  TriggerButton,
  title,
  description,
  triggerProps,
  contentClassName,
  children,
}: ISidePanelProps) => {
  return (
    <RadixDialog
      title={title}
      description={description}
      TriggerButton={TriggerButton}
      withCloseButton
      triggerProps={triggerProps}
      contentProps={{
        className: classNames(
          "SidePanelDialogContent pb-0 right-0 top-0 bottom-0 -translate-x-0 -translate-y-0 left-auto max-h-screen rounded-none border-r-0 border-t-0 border-b-0",
          contentClassName,
        ),
      }}
    >
      {props => children(props)}
    </RadixDialog>
  )
}

export default SidePanel
