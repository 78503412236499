import { UserManagementService } from "@behaviour-lab/blab-component-library/auth"

import { sleep } from "./sleep"

export type TokenExpiredCbType = () => void

export async function handleRefreshTokenAndRetry<DataType>({
  url,
  retry,
  tokenExpiredCb,
}: {
  url: string
  retry: () => Promise<DataType>
  tokenExpiredCb?: TokenExpiredCbType
}) {
  try {
    const isRefreshing = UserManagementService.getIsRefreshingInProgress()

    console.info(
      `Invalid token on request: ${url}. Refreshing status: ${isRefreshing}.`,
    )

    if (!isRefreshing) {
      console.info(`Refreshing token on ${url}.`)

      await UserManagementService.refreshToken(tokenExpiredCb)
    }

    await sleep(2000)

    return await retry()
  } catch (e) {
    console.error(`Error on refresh and retry`, e)

    return Promise.reject(e)
  }
}
